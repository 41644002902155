// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/cute-dress/header.jpg";
import Dollar from "../images/confi/dollar.svg";
import Globe from "../images/confi/globe.svg";
import GreenHeart from "../images/confi/greenHeart.svg"
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Size from "../images/confi/size.svg";
import Profile from "../images/confi/profile.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import ProfilePicture from "../images/cute-dress/profile.jpg";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("cute-dress-industry.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("cute-dress-industry.subTitle"),
        colorClass: "colorLight",
      },
      /*
      cta: {
        text: t("cute-dress-industry.topCta"),
        colorClass: "colorLight",
        url: t("cute-dress-industry.ctaUrl"),
        type: "mainColor",
      }, */
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("cute-dress-industry.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("cute-dress-industry.newMarketsValue"),
        },
        subtitle: {
          text: t("cute-dress-industry.newMarketsSubtitle"),
          colorClass: "colorDark",
        },
        icon: {
          src: Globe,
          alt: t("cute-dress-industry.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("cute-dress-industry.roiValue"),
        },
        subtitle: {
          text: t("cute-dress-industry.roiSubtitle"),
          colorClass: "colorDark",
        },
        icon: {
          src: Dollar,
          alt: t("cute-dress-industry.roiAlt"),
        },
      },
      {
        title: {
          text: t("cute-dress-industry.recruitingValue"),
        },
        subtitle: {
          text: t("cute-dress-industry.recruitingSubtitle"),
          colorClass: "colorDark",
        },
        icon: {
          src: GreenHeart,
          alt: t("cute-dress-industry.recruitingAlt"),
        },
      },
    ],
    sideIcons1: [
      {
        title: {
          text: t("cute-dress-industry.locationTitle"),
        },
        subtitle: {
          text: t("cute-dress-industry.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("cute-dress-industry.locationAlt"),
        },
      },
      {
        title: {
          text: t("cute-dress-industry.productTitle"),
        },
        subtitle: {
          text: t("cute-dress-industry.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("cute-dress-industry.productAlt"),
        },
      },
      {
        title: {
          text: t("cute-dress-industry.memberTitle"),
        },
        subtitle: {
          text: t("cute-dress-industry.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("cute-dress-industry.memberAlt"),
        },
      },
      {
        title: {
          text: t("cute-dress-industry.membershipTitle"),
        },
        subtitle: {
          text: t("cute-dress-industry.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("cute-dress-industry.membershipAlt"),
        },
      },
      {
        title: {
          text: t("cute-dress-industry.sizeTitle"),
        },
        subtitle: {
          text: t("cute-dress-industry.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("cute-dress-industry.sizeAlt"),
        },
      },
      {
        title: {
          text: t("cute-dress-industry.sinceTitle"),
        },
        subtitle: {
          text: t("cute-dress-industry.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("cute-dress-industry.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("cute-dress-industry.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("cute-dress-industry.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("cute-dress-industry.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("cute-dress-industry.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("cute-dress-industry.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("cute-dress-industry.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    giulio: [
      {
        title: {
          text: t("cute-dress-industry.giulioName"),
        },
        subtitle: {
          text: t("cute-dress-industry.giulioTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ProfilePicture,
          alt: t("cute-dress-industry.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("cute-dress-industry.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("cute-dress-industry.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("cute-dress-industry.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("cute-dress-industry.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("cute-dress-industry.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("cute-dress-industry.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("cute-dress-industry.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("cute-dress-industry.bullet1"),
          t("cute-dress-industry.bullet2"),
          t("cute-dress-industry.bullet3"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    cta: {
      text: t("cute-dress-industry.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("cute-dress-industry.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("cute-dress-industry.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("cute-dress-industry.favoriteToolsExtra"),
    profileText: t("cute-dress-industry.profileText"),
    testimonyName: t("cute-dress-industry.giulioName"),
    testimonyTitle: t("cute-dress-industry.giulioTitle"),
    anchorText: t("cute-dress-industry.anchorText")
  };
};
